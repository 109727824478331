<template>
    <div class="l-default bg-brand-1 t-confirmpassword">
        <Sprite />
        <svg
            class="l-default__background"
            viewBox="0 0 1920 682"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            preserveAspectRatio="none"
        >
            <path
                d="M-12 -17H1933V573.652C1424.28 764.929 996.99 568.64 635.162 568.64C379.98 568.64 191.077 588.689 -12 681V-17Z"
                fill="#4942CA"
                stroke="#E2E6F8"
                stroke-linecap="round"
            />
        </svg>

        <header class="o-header">
            <div class="o-header__logo">
                <a href="/" title="SEM Juice">
                    <svg class="a-svg -logo" aria-hidden="true" focusable="false">
                        <use xlink:href="#icon-nl-logo"></use>
                    </svg>
                </a>
            </div>
        </header>

        <main>
            <div class="l-default__container">
                <div class="l-default__panel">
                    <div class="o-panel">
                        <section class="o-panel__main">
                            <h2 class="text-l o-panel__main--title">Nouveau mot de passe</h2>
                            <p class="text-pm text-nl-neutral-6">
                                Choisissez le mot de passe que vous souhaitez utiliser pour votre compte Semjuice App.
                            </p>

                            <form action="" method="POST" class="o-panel__form">
                                <div class="m-form__fieldset">
                                    <div class="m-form__fields">
                                        <div>
                                            <label for="email" class="text-label">
                                                Nouveau mot de passe <span class="required">*</span>
                                            </label>
                                            <input
                                                id="password"
                                                v-model="password"
                                                class="a-input -password"
                                                name="pass"
                                                type="password"
                                                placeholder="Choisissez un mot de passe"
                                            />
                                        </div>
                                        <div>
                                            <label for="email" class="text-label">
                                                Confirmation du nouveau mot de passe <span class="required">*</span>
                                            </label>
                                            <input
                                                id="password_confirmation"
                                                v-model="passwordConfirmation"
                                                class="a-input -password"
                                                name="pass"
                                                type="password"
                                                placeholder="Confirmez le mot de passe"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-5">
                                    <p class="m-0 pb-1">
                                        <span :class="{ 'checkmark-expectations': expectations.length }"
                                            ><i class="fas fa-check"></i
                                        ></span>
                                        8 caractères
                                    </p>
                                    <p class="m-0 pb-1">
                                        <span :class="{ 'checkmark-expectations': expectations.uppercase }"
                                            ><i class="fas fa-check"></i
                                        ></span>
                                        majuscule(s)
                                    </p>
                                    <p class="m-0 pb-1">
                                        <span :class="{ 'checkmark-expectations': expectations.numbers }"
                                            ><i class="fas fa-check"></i
                                        ></span>
                                        chiffre(s)
                                    </p>
                                    <p class="m-0 pb-1">
                                        <span :class="{ 'checkmark-expectations': expectations.symbols }"
                                            ><i class="fas fa-check"></i
                                        ></span>
                                        symbole(s)
                                    </p>
                                </div>

                                <button
                                    class="a-button -primary"
                                    :class="{ '-disabled': checkDisabledButton }"
                                    @click.prevent="doUpdatePasswordReset"
                                    :disabled="checkDisabledButton"
                                >
                                    <span>Modifier mon mot de passe NextLevel</span>
                                    <svg class="a-svg arrow" aria-hidden="true" focusable="false">
                                        <use xlink:href="#icon-arrow-right"></use>
                                    </svg>
                                </button>
                            </form>
                        </section>
                    </div>
                    <img class="l-default__illu -lock" src="@/assets/img-v2/lock.svg" alt="" width="120" />
                </div>
            </div>
        </main>
    </div>
</template>

<style>
    .checkmark-expectations {
        color: #5cb270;
    }
</style>

<script>
    import Sprite from '@/views/Svg/Sprite.vue'
    import Toaster from '@/components/UI/Toaster'
    import { mapActions } from 'vuex'

    export default {
        name: 'ForgottenPasswordStep2',
        title: 'register.forgotten-password',
        components: {
            Sprite,
            Toaster
        },
        watch: {
            checkDisabledButton() {
                return !(
                    this.expectations.length &&
                    this.expectations.numbers &&
                    this.expectations.symbols &&
                    this.expectations.uppercase &&
                    this.password === this.passwordConfirmation
                )
            },
            password: function (p) {
                this.expectations.length = p.length >= 8
                this.expectations.numbers = p.search(/[0-9]/) >= 0
                this.expectations.uppercase = p.search(/[A-Z]/) >= 0

                const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/
                this.expectations.symbols = isContainsSymbol.test(p)
            }
        },
        data: function () {
            return {
                message: {},
                loader: false,
                password: null,
                passwordConfirmation: null,
                redirectUrl: this.$route.query.redirect,
                expectations: {
                    symbols: false,
                    numbers: false,
                    length: false,
                    uppercase: false
                }
            }
        },
        computed: {
            checkDisabledButton() {
                return !(
                    this.expectations.length &&
                    this.expectations.numbers &&
                    this.expectations.symbols &&
                    this.expectations.uppercase &&
                    this.password === this.passwordConfirmation
                )
            },
        },
        methods: {
            ...mapActions('auth', ['updatePasswordReset']),
            doUpdatePasswordReset() {
                this.updatePasswordReset({
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    redirectUrl: this.redirectUrl
                })
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: this.$t('oubli-etape-2.passwordChangedTitle'),
                            message: this.$t('oubli-etape-2.passwordChangedMessage')
                        })
                        this.$router.push('/login')
                    })
                    .catch(error => {
                        this.$store.dispatch('toastError', error)
                    })
            }
        },
        mounted() {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = "/css/app.css"
            link.id = 'customCss';

            document.head.appendChild(link);
        },
        beforeDestroy() {
            const link = document.getElementById('customCss');
            if (link) link.remove();
        }
    }
</script>
